.property-search-view {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
}

.filter-area {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 20px;
    justify-content: center;
}

.filter-area select,
.filter-area input {
    padding: 8px 12px;
    font-size: 1em;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
    width: 150px;
    box-sizing: border-box;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
}

.card-container p {
    font-size: 1.2em;
    color: #666;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination button {
    margin: 0 4px;
    padding: 8px 12px;
    font-size: 1em;
    border: 1px solid #ddd;
    background-color: white;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    border-radius: 4px;
    color: #333; /* Set text color for better visibility */
}

.pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
    transform: none;
    color: #999;
}

.pagination button:hover:not(:disabled) {
    background-color: #f0f0f0;
    transform: scale(1.05);
}

.pagination .active {
    background-color: #002349;
    color: white;
    font-weight: bold;
}
