.featured-space {
  background-color: #002349;
  color: white;
  display: flex;
  flex-direction: column; /* Adjusts layout for smaller screens */
  padding: 20px;
  align-items: center;
}

.featured-space .text-half {
  width: 100%;
  text-align: center; /* Center align text for all screen sizes */
}

.featured-space .text-half h2 {
  font-size: 2rem; /* Adjusted for responsiveness */
  color: white;
  margin-bottom: 20px;
}

.featured-space .image-half {
  width: 100%; /* Allows full width on smaller devices */
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.image-carousel {
  display: flex;
  transition: transform 1s ease;
  will-change: transform;
}

.image-wrapper {
  min-width: calc(100% / 3 - 20px); /* Adjust width relative to visible images */
  margin: 0 10px; /* Space between images */
  position: relative;
}

.image-wrapper img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px;
}

.image-wrapper .more-details-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 20px;
  border-radius: 50px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.image-wrapper:hover img {
  filter: brightness(50%);
}

.image-wrapper:hover .more-details-button {
  opacity: 1;
  visibility: visible;
}

/* Responsive adjustments */

@media (max-width: 768px) {
  .image-wrapper {
    min-width: calc(100% / 2 - 20px); /* Show 2 images on medium devices */
  }

  .featured-space .text-half h2 {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .image-wrapper {
    min-width: 100%; /* Show 1 image on smaller devices */
  }

  .featured-space .text-half h2 {
    font-size: 1.2rem;
  }
}
