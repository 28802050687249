.about-us {
  background-color: #ffffff; /* White background */
  color: #000000; /* Black text */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 50px; /* Reduced top and bottom padding */
  height: auto; /* Allow the content to dictate the height */
}

.about-us .image-half {
  flex: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px; /* Add space between image and text */
}

.about-us .image-half img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

.about-us .text-half {
  flex: 1;
  padding: 10px; /* Reduced inner padding */
}

.about-us .text-half h2 {
  font-size: 2.5rem; /* Ideally responsive size */
  margin-bottom: 20px;
}

.about-us .text-half p {
  font-size: 1.2rem;
  line-height: 1.5;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .about-us {
    flex-direction: column; /* Stack image and text on smaller screens */
    padding: 20px; /* Maintained consistent padding */
  }

  .about-us .image-half {
    margin-right: 0; /* Remove margin on smaller screens */
    margin-bottom: 20px; /* Add some space below the image */
  }

  .about-us .text-half {
    width: 100%; /* Full width for text on smaller screens */
    text-align: center; /* Center-align text for better symmetry */
  }

  .about-us .text-half h2 {
    font-size: 2rem; /* Responsive font size */
  }

  .about-us .text-half p {
    font-size: 1rem; /* Responsive paragraph size */
  }
}
