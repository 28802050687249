.contact-us {
  background-color: #002349;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; /* Center items horizontally */
  padding: 20px;
  height: auto; /* Allow content to dictate height */
  overflow: hidden; /* Prevent overflow */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.contact-us .form-half,
.contact-us .text-half {
  flex: 1 1 45%; /* Allow some flexibility and reduce size to fit better */
  padding: 30px;
  margin: 10px; /* Add some space between the two sections */
  overflow: auto; /* Enable scrolling if needed */
  min-width: 300px; /* Ensure a minimum width for responsive design */
}

.contact-us .form-half {
  background-color: #ffffff;
  color: #002349;
  border-radius: 10px;
  padding: 40px;
}

.contact-us .text-half p {
  font-size: 1.1rem; /* Slightly adjust font size for consistency */
  line-height: 1.5;
  color: white;
}

.contact-us .contact-form {
  display: flex;
  flex-direction: column;
}

.contact-us .contact-form input {
  margin-bottom: 15px;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #cccccc;
  border-radius: 5px;
}

.contact-us .contact-form button {
  background-color: #002349;
  color: #ffffff;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-us .contact-form button:hover {
  background-color: #003366; /* Darker shade on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .contact-us {
    flex-direction: column; /* Stack form and text vertically on smaller screens */
    padding: 20px;
    height: auto; /* Ensure content adjusts height */
  }

  .contact-us .form-half,
  .contact-us .text-half {
    flex: 1 1 100%; /* Full width for smaller screens */
    margin: 5px 0; /* Adjust margins for better spacing */
  }

  .contact-us .form-half h2,
  .contact-us .text-half h2 {
    font-size: 1.8rem; /* Adjust font size for smaller screens */
  }

  .contact-us .text-half p {
    font-size: 1rem; /* Adjust paragraph size */
  }
}
