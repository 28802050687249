.AmenitiesSection {
    /* Add any existing styles you have */
    padding: 60px;
  }
  
  .AmenitiesHeading {
    /* Add any existing styles you have */
    padding-bottom: 20px;
    font-weight: bold;
    text-decoration: underline;
    font-size: 2.0rem;
  }
  
  .AmenitiesGrid {
    display: flex;
  }
  
  .AmenitiesList {
    list-style-type: disc; /* Ensures bullet points are used */
    padding-left: 20px; /* Space for bullets */
    margin: 0;
    margin-right: 60px; /* Space between columns */
  }
  
  .AmenitiesList li {
    margin-bottom: 10px; /* Equal spacing between list items */
  }
  
  .AmenitiesList:last-child {
    margin-right: 0; /* No margin for the last column */
  }
  