.project-contact-container {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  width: 100%;
  margin: 0 auto;
  align-items: stretch;
  gap: 20px;
}

.project-summary-half, .contact-us {
  flex: 1;
  background-color: #ffffff;
  padding: 50px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
  margin-bottom: 20px; /* Add spacing between sections on smaller screens */
}

.project-summary-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
}

.project-summary-table td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  color: #333;
}

.project-summary-table .icon-text {
  display: flex;
  align-items: center;
}

.project-summary-table .icon {
  margin-right: 10px;
  color: #002349;
}

.project-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  color: #333;
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
}

.contact-us {
  background-color: #002349;
  color: #002349;
  padding: 40px;
}

.contact-us h2 {
  font-size: 2rem;
  color: #002349;
  margin-bottom: 20px;
}

.contact-us .contact-form {
  display: flex;
  flex-direction: column;
}

.contact-us .contact-form input {
  margin-bottom: 15px;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #cccccc;
  border-radius: 5px;
}

.contact-us .contact-form button {
  background-color: #002349;
  color: #ffffff;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-us .contact-form button:hover {
  background-color: #001a33;
}

@media (max-width: 768px) {
  .project-contact-container {
      flex-direction: column; /* Stack vertically on smaller screens */
  }

  .project-summary-half, .contact-us {
      padding: 20px; /* Reduce padding on smaller screens */
      margin-bottom: 20px; /* Ensure there's space between sections */
  }

  .project-title, .contact-us h2 {
      font-size: 1.5rem; /* Adjust the header font size */
  }

  .project-summary-table td, .contact-us .contact-form input {
      padding: 10px; /* Reduce cell padding on smaller screens */
  }

  .contact-us .contact-form input, .contact-us .contact-form button {
      font-size: 0.9rem; /* Slightly reduce input and button font size */
  }
}
