/* General navbar styles */
.navbar-custom {
  height: 150px; /* Fixed height for larger screens */
  background-color: #002349; /* Set background color */
  color: #fff; /* Set text color to white */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 991px) {
  .navbar-custom {
    height: auto; /* Let height adjust naturally on smaller screens */
    padding: 1rem 0; /* Add padding for smaller screens */
  }

  .navbar-custom .navbar-brand {
    padding-left: 1rem; /* Reduce left padding */
    font-size: 1.5rem;  /* Reduce brand text size */
  }

  .navbar-custom .nav-link {
    padding-right: 1rem; /* Reduce right padding */
    font-size: 1.2rem; /* Reduce nav link text size */
    margin-right: 0.5rem; /* Adjust margin for better spacing */
  }
}

.navbar-custom .navbar-brand {
  font-size: 2rem; /* Default size for larger screens */
  padding-left: 70px; /* Spacing from the left screen edge */
  color: #fff;
}

.navbar-custom .navbar-brand:hover,
.navbar-custom .navbar-brand:focus {
  color: #fff;
}

.navbar-custom .nav-link {
  font-size: 1.5rem; /* Default size for larger screens */
  margin-right: 15px;
  padding-right: 70px;
  color: #fff;
  position: relative;
  display: flex;
  align-items: center;
}

.navbar-custom .nav-link:hover,
.navbar-custom .nav-link:focus {
  color: #fff;
}

/* Add golden underline on hover */
.navbar-custom .nav-link::after {
  content: '';
  position: absolute;
  width: 0%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: rgb(230, 197, 12);
  transition: width 0.3s;
}

.navbar-custom .nav-link:hover::after {
  width: 100%;
}

.navbar-custom .navbar-collapse {
  justify-content: flex-end;
}

.navbar-custom .nav-link .bi-telephone-fill {
  color: rgb(230, 197, 12);
}
