/* Hero section styles */
.hero {
  position: relative;
  width: 100%;
  height: 80vh;
  background-image: url('/public/images/dubaiHeroEnhanced.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center content */
  align-items: center; /* Horizontally center content */
  color: #fff;
  text-align: center;
  box-sizing: border-box;
}

/* Title text styles */
.hero-title {
  margin-bottom: 20px; /* Space below the title */
  font-size: 2rem;
  font-weight: bold;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
  z-index: 2;
  width: 100%; /* Ensure the title takes full width */
  text-align: center; /* Center text in the title */
  font-family: 'Roboto', sans-serif;
}

/* Form styles */
.hero-form {
  position: absolute;
  bottom: -25px; /* Position form half inside/half outside image */
  left: 50%;
  transform: translateX(-50%); /* Center the form horizontally */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 90%;
  max-width: 1200px;
  background: rgba(120, 116, 116, 0.8);
  border-radius: 10px;
  gap: 10px;
  z-index: 3;
}

/* Styles for labels */
.hero-form label {
  padding: 10px;
  font-size: 0.9rem;
}

/* Styles for inputs */
.hero-input {
  margin: 5px;
  padding: 10px;
  font-size: 1rem;
  border: 2px solid rgb(206, 178, 21);
  border-radius: 5px;
  flex: 1;
  min-width: 150px;
}

/* Styles for button */
.hero-button {
  background-color: #002349;
  color: #fff;
  border: 2px solid gold;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 50px;
  width: 100%;
  max-width: 300px;
}

/* Responsiveness */
@media (max-width: 768px) {
  .hero-title {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 1.2rem;
  }
}
