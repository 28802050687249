.property-finder-hero {
  position: relative;
  width: 100%;
  height: 50vh; /* Adjust height as needed */
  background-image: url('/public/images/SearchPropertyHero.jpg'); /* Update the path to your image */
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
}

.property-finder-hero-content {
  background-color: rgba(0, 0, 0, 0.01); /* Optional: Add a background color with opacity for better text readability */
  padding: 20px;
}
  