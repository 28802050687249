.card {
  overflow: hidden;
  text-align: left;
  position: relative;
  max-width: 450px; /* Reduced maximum width */
  width: 100%;
  border-radius: 20px;
  height: 500px; /* Reduced height for the card */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  margin: 10px; /* Added margin for spacing between cards */
}

.card:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
  transform: scale(1.02);
}

.card-carousel {
  position: relative;
  overflow: hidden;
  height: 200px; /* Adjusted height for card image */
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.card:hover .card-image {
  transform: scale(1.1);
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.carousel-button.left {
  left: 10px;
}

.carousel-button.right {
  right: 10px;
}

.card-content {
  padding: 10px;
}

.card-content h3 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
}

.card-content p,
.card-location,
.card-property-type,
.card-details,
.card-size {
  margin: 5px 0;
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.card-content p svg,
.card-location svg,
.card-property-type svg,
.card-details svg,
.card-size svg {
  margin-right: 0.5em;
}

.card-footer {
  padding: 10px;
  text-align: center;
}

.show-detail {
  background-color: #002349;
  color: #fff;
  border: none;
  padding: 10px 60px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 20px;
}

.show-detail:hover {
  background-color: #001530;
}
