.container {
    padding: 20px;
  }
  
  .key-highlights {
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
    background-color: #f8f9fa;
    border-radius: 8px;
  }
  
  .key-highlights h2 {
    text-decoration: underline;
    font-weight: bold;
    color: #333;
    padding-bottom: 30px;
  }
  
  .key-highlights ul {
    list-style-type: none;
    padding: 0;
  }
  
  .key-highlights li {
    margin-bottom: 10px;
    font-size: 16px;
    color: #171717;
  }
  