.LocationConnectivityContainer {
    padding: 60px; /* Padding around the whole component */
    box-sizing: border-box;
}

.LocationHeading {
    font-weight: bold;
    color: black;
    text-align: left;
    margin-bottom: 10px;
    font-size: 2.0rem;
    border-bottom: 2px solid black; /* Underline */
    padding-bottom: 5px; /* Padding below the text before underline */
}

.LocationConnectivity {
    display: flex;
    gap: 20px; /* Space between image and text */
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
}

.LocationImage {
    flex: 1;
    width: 50%; /* Image occupies half the width */
}

.LocationImage img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px; /* Optional rounded corner */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Optional shadow */
}

.Connectivity {
    flex: 1;
    width: 50%; /* Connectivity text occupies half the width */
    padding: 20px;
    box-sizing: border-box;
}

.Connectivity h2 {
    color: black;
    font-weight: bold;
    margin-bottom: 15px;
    font-size: 1.5rem;
}

.Connectivity ul {
    list-style-type: disc;
    margin: 0;
    padding-left: 20px;
}

.Connectivity li {
    margin: 10px 0;
    font-size: 1rem;
    line-height: 1.5;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .LocationConnectivity {
        flex-direction: column; /* Stack items vertically on small screens */
    }

    .LocationImage, .Connectivity {
        width: 100%; /* Full width on smaller screens */
        padding: 10px 0; /* Reduce padding for better fit */
    }

    .LocationHeading {
        font-size: 1.8rem; /* Adjust the font size for smaller screens */
    }

    .Connectivity h2 {
        font-size: 1.3rem; /* Reduce subheading font size */
    }
}
