/* Footer Styles (Footer.css) */

.footer-custom {
    background-color: #002349;
    color: #fff;
    padding: 40px 100px;
}

.footer-title {
    font-size: 1.5rem; /* Smaller font size for the titles */
    margin-bottom: 20px;
}

.footer-links,
.footer-contact {
    list-style-type: none;
    padding: 15px; /* Add padding around the lists */
}

.footer-links li,
.footer-contact li {
    margin-bottom: 15px; /* More space between list items */
    font-size: 1.2rem; /* Smaller font size for the list items */
}

.footer-links a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
}

.footer-links a:hover {
    color: rgb(230, 197, 12);
}

.bi-instagram,
.bi-twitter,
.bi-linkedin,
.bi-facebook,
.bi-telephone,
.bi-whatsapp,
.bi-geo-alt {
    margin-right: 10px;
    color: rgb(230, 197, 12);
}

.footer-custom .row {
    margin-right: 20px; /* Move the entire row slightly to the right */
}
