.partners {
  background-color: white; 
  color: #002349;
  padding: 40px; /* Reduced padding to fit better on smaller screens */
  text-align: center;
}

.partners h2 {
  font-size: 2rem;
  margin-bottom: 30px;
  font-weight: bold;
}

.partners-container {
  display: flex;
  justify-content: space-around; /* Equal spacing around items */
  align-items: center;
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
}

.partner-image {
  margin: 10px; /* Add some margin around each image for spacing */
}

.partner-image img {
  width: 150px; /* Fixed width for equal size */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .partners h2 {
    font-size: 1.8rem; /* Adjust heading size for smaller screens */
  }

  .partners-container {
    justify-content: center; /* Center images in the container */
  }

  .partner-image img {
    width: 120px; /* Reduce image size for smaller screens */
  }
}
