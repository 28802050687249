.download-brochure-button {
    background-color: #002349;
    color: #ffffff;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-left: 60px;
    margin-bottom: 10px;
  }
  
  .download-brochure-button:hover {
    background-color: #001a33;
  }
  