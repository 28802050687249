.contact-us {
    flex: 1;
    background-color: #002349;
    color: #002349;
    border-radius: 10px;
    padding: 40px;
  }
  
  .contact-us .form-half {
    width: 100%;
  }
  
  .contact-us h2 {
    font-size: 2rem;
    color: #002349;
    margin-bottom: 20px;
  }
  
  .contact-us .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-us .contact-form input {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #cccccc;
    border-radius: 5px;
  }
  
  .contact-us .contact-form button {
    background-color: #002349;
    color: #ffffff;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .contact-us .contact-form button:hover {
    background-color: #001a33;
  }
  