.recent-projects {
  padding: 70px 20px;
  text-align: center;
}

.recent-projects h2 {
  font-size: 2.5rem;
  text-align: center;
  font-family: 'Roboto';
  color: #002349;
}

.projects-grid {
  padding: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-items: center;
}

.load-more {
  grid-column: span 3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.load-more-button {
  background-color: #002349;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.load-more-button:hover {
  background-color: #001530;
}
