.ProjectContainer {
    display: flex; /* Use flexbox to align children side by side */
    flex-wrap: wrap; /* Allow children to wrap on smaller screens */
}

.ProjectOverview {
    width: 55%; /* Use 55% width for the overview section */
    padding-top: 60px;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 20px;
    box-sizing: border-box;
    text-align: left;
}

.ProjectOverview h2 {
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: 10px;
    padding-bottom: 30px;
    font-size: 2.0rem;
}

.ProjectImage {
    width: 45%; /* Use the remaining 45% for the image */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px;
    box-sizing: border-box;
}

.ProjectImage img {
    max-width: 100%; /* Ensure the image fits within the container */
    height: auto;
    border-radius: 8px; /* Optional: add a border-radius for styling */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .ProjectContainer {
        flex-direction: column; /* Stack items vertically on smaller screens */
    }

    .ProjectOverview, .ProjectImage {
        width: 100%; /* Take full width on smaller screens */
        padding: 20px; /* Reduce padding for better fit on smaller screens */
    }

    .ProjectOverview h2 {
        font-size: 1.5rem; /* Adjust font size for smaller screens */
    }
}
