.hero {
  position: relative;
  width: 100%;
  height: 80vh; /* Adjust height as needed */
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center; /* Center horizontally (not necessary with absolute positioning) */
  align-items: flex-end; /* Align items towards the bottom (effective for content container) */
  color: #fff;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.hero-content {
  position: absolute;
  bottom: 20px; /* Position at the bottom with a margin */
  left: 50%;
  transform: translateX(-50%);
  /*background-color: rgba(224, 221, 221, 0.5); /* Semi-transparent background for readability */
  border-radius: 10px;
  padding: 20px;
  width: auto; /* Allow the content to take necessary space */
  max-width: 600px; /* Limit the width if needed */
  font-family: 'Roboto', sans-serif;
}

.hero h1, .hero h2 {
  margin-bottom: 10px;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7);
}

.hero h1 {
  font-size: 3rem;
}

.hero h2 {
  font-size: 1.5rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hero h1 {
      font-size: 2.5rem;
  }

  .hero h2 {
      font-size: 1.2rem;
  }

  .hero-content {
      padding: 15px;
  }
}
